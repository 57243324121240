import {HubConnectionBuilder, LogLevel} from "@aspnet/signalr";
import emitter from 'tiny-emitter/instance';

export default {
    _accessToken: null,
    _connection: {},

    install(Vue, hubUrl) {
        let self = this;
        this._connection = new HubConnectionBuilder()
            .withUrl(hubUrl, {
                accessTokenFactory() {
                    return self._accessToken;
                }
            })
            .configureLogging(LogLevel.Information)
            .build();

        this._connection.on("CommandUpdated", data => {
            emitter.emit("CommandUpdated", data);
        });

        this._connection.on("CommandDeleted", (commandName, commandId) => {
            emitter.emit("CommandDeleted", commandName, commandId);
        });

        this._connection.on("CommandIndexed", (commandName, commandId) => {
            emitter.emit("CommandIndexed", commandName, commandId);
        });
    },

    async start(accessToken) {
        this._accessToken = accessToken;
        await this._connection.start();
    },

    async stop() {
        await this._connection.stop();
    }
};
