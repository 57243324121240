let _roles = () => {
    let roles = {};

    roles.isAdmin = role => {
        return role === "admin" || role === "owner";
    };

    return roles;
};

export default _roles();
