<template>
    <BButton class="goTop" v-if="isVisible" @click="backToTop">
        <arrow-up-icon></arrow-up-icon>
    </BButton>
</template>

<script>
    import $ from "jquery";
    import { ArrowUpIcon } from 'vue-feather-icons'

    export default {
        name: "BackToTop",
        components: {
            ArrowUpIcon
        },
        data: function () {
            return {
                isVisible: false
            };
        },
        methods: {
            initToTopButton: function () {
                $(document).bind('scroll', function () {
                    let backToTopButton = $('.goTop');
                    if ($(document).scrollTop() > 250) {
                        backToTopButton.addClass('isVisible');
                        this.isVisible = true;
                    } else {
                        backToTopButton.removeClass('isVisible');
                        this.isVisible = false;
                    }
                }.bind(this));
            },
            backToTop: function () {
                $('html,body').stop().animate({
                    scrollTop: 0
                }, 'slow', 'swing');
            }
        },
        mounted: function () {
            this.$nextTick(function () {
                this.initToTopButton();
            });
        }
    }
</script>

<style lang="scss" scoped>
    .goTop {
        border-radius: 5px;
        background-color: rgb(1,14,27);
        background-color: rgba(1, 14, 27, .7);
        position: fixed;
        width: 45px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 15px;
        bottom: 15px;
        border: none;
        opacity: 0;
        z-index: 4;
        .fa {
            color: white;
            font-size: 22px;
        }
        &:hover {
            opacity: 1;
            background-color: rgb(1,14,27);
            background-color: rgba(1, 14, 27, .9);
        }
    }

    .isVisible {
        opacity: .8;
        z-index: 4;
        transition: all .4s ease-in;
    }
</style>
