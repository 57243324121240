import {createApp} from 'vue'
import App from "./App.vue";
import api from "./js/api.js";
import modules from "./js/modules";
import "bootstrap";
import bootstrap from "bootstrap-vue";
import "./assets/style.scss";
import VueToasted from "vue-toasted";
import VueLazyload from "vue-lazyload";
import VueLazyLoadVideo from "vue-lazyload-video";
import DefaultLayout from "./layout/DefaultLayout";
import AdminLayout from "./layout/AdminLayout";
import Cache from "@/js/cache";
import UserManager from "@/js/userManager";
import AdminHub from "./hubs/admin-hub";
import BackToTop from "@/components/BackToTop";
import router from  "./router";
import DefaultNoMarginLayout from "@/layout/DefaultNoMarginLayout.vue";

//#region Initialize settings

api.url = window.nbot.api.url;
api.admin.url = window.nbot.api.admin.url;

if (window.nbot.clientSettings) {
  let settings = window.nbot.clientSettings;

  if (settings.AvailableModules) {
    modules.setModules(settings.AvailableModules);
  }

  if (settings.NsfwSourcesFactory) {
    modules.setDefaultNsfwSourcesFactory(eval(settings.NsfwSourcesFactory));
  }
}

const app = createApp({
  render: h => h(App),
});
app.use(router);

//#endregion

//#region UI

app.use(bootstrap);
app.use(VueToasted);
app.use(VueLazyload);
app.use(VueLazyLoadVideo);

//#endregion

//#region Layouts

app.component("default-layout", DefaultLayout);
app.component("default-no-margin-layout", DefaultNoMarginLayout);
app.component("admin-layout", AdminLayout);
app.component("backtotop", BackToTop);

//#endregion

//#region Components

app.use(AdminHub, window.nbot.hub.admin.url);

app.config.globalProperties.$randomGuid = function b(a) {
  return a
    ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
    : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, b);
};

app.config.globalProperties.$api = api;
app.config.globalProperties.$cache = new Cache();

let userManager = new UserManager();
app.config.globalProperties.$userManager = userManager;

//#endregion

userManager.loadUser().then();
router.beforeEach((to, from, next) => {

  // Redirect to home if user not admin on admin pages
  if (to.meta && to.meta.layout === "admin" && !userManager.data.isAdmin) {
    next({name: "Home"});
    return;
  }

  // Redirect to home from login if already logged in
  if (to.name === "Login" && userManager.data.isLoggedIn) {
    next({name: "Home"});
    return;
  }

  next();
});

userManager.init(app);

app.mount("#app");
