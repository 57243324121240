import {createRouter, createWebHistory} from "vue-router";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "Home",
      component: () =>
        import(/* webpackChunkName: "HomeView" */ "./views/HomeView.vue")
    },
    {
      path: "/login/callback",
      name: "Login callback",
      component: () =>
        import(/* webpackChunkName: "LoginCallbackView" */ "./views/LoginCallbackView.vue")
    },
    {
      path: "/login",
      name: "Login",
      component: () =>
        import(/* webpackChunkName: "LoginView" */ "./views/LoginView.vue")
    },
    {
      path: "/dungeon/:guildId/:dungeonId",
      name: "View Dungeon",
      component: () =>
        import(/* webpackChunkName: "DungeonView" */ "./views/DungeonView.vue"),
      meta: {
        layout: "default-no-margin"
      }
    },
    {
      path: "/dungeon/list",
      name: "Dungeon List",
      component: () =>
        import(/* webpackChunkName: "DungeonListView" */ "./views/DungeonListView.vue")
    },
    {
      path: "/guild/list",
      name: "Guild List",
      component: () =>
        import(/* webpackChunkName: "GuildListView" */ "./views/GuildListView.vue")
    },
    {
      path: "/guild/:guildId",
      name: "View Guild",
      component: () =>
        import(/* webpackChunkName: "GuildView" */ "./views/GuildView.vue")
    },
    {
      path: "/browse",
      name: "Browse Picture Categories",
      component: () =>
        import(/* webpackChunkName: "BrowseView" */ "./views/BrowseView.vue")
    },
    {
      path: "/browse/:category",
      name: "Browse Pictures",
      component: () =>
        import(/* webpackChunkName: "BrowseView" */ "./views/BrowseView.vue")
    },
    //#region Admin
    {
      path: "/admin",
      name: "Admin Home",
      component: () =>
        import(/* webpackChunkName: "AdminCommandsListView" */ "./views/admin/AdminHomeView.vue"),
      meta: {layout: "admin"}
    },
    {
      path: "/admin/commands/:commandId",
      name: "Command Edit",
      component: () =>
        import(/* webpackChunkName: "AdminCommandEditView" */ "./views/admin/AdminCommandEditView/AdminCommandEditView.vue"),
      meta: {layout: "admin"}
    },
    {
      path: "/admin/commands",
      name: "Commands List",
      component: () =>
        import(/* webpackChunkName: "AdminCommandsListView" */ "./views/admin/AdminCommandsListView.vue"),
      meta: {layout: "admin"}
    }
    //#endregion
  ]
});

export default router;