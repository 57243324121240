import Vue from "vue";
import json5 from "json5";
import api from "@/js/api"
import roles from "@/js/roles"
import AdminHub from "@/hubs/admin-hub";
import router from  "@/router";

function userFromPayload(payload, timeBefore) {
  return {
    token: payload.token,
    username: payload.username,
    userId: payload.userId,
    role: payload.role,
    expiresAt: timeBefore + payload.expiresIn
  };
}

export default class UserManager {
  constructor() {
    // vue instance holding data for this class
    this.data = new Vue({
      data() {
        return {
          m_username: "",
          m_userId: "",
          m_role: "",
          m_expiresAt: 0,
          m_loggedIn: false,
        }
      },

      computed: {
        username: {
          get() {
            return this.m_username;
          }
        },
        userId: {
          get() {
            return this.m_userId;
          }
        },
        role: {
          get() {
            return this.m_role;
          }
        },
        isExpired: {
          get() {
            return Math.round(Date.now() / 1000) >= this.m_expiresAt;
          }
        },
        isLoggedIn: {
          get() {
            return this.m_loggedIn;
          }
        },
        isAdmin: {
          get() {
            if (!this.m_loggedIn)
              return false;

            return roles.isAdmin(this.m_role);
          }
        }
      },

      methods: {
        setUser(user) {
          this.m_username = user.username;
          this.m_userId = user.userId;
          this.m_role = user.role;
          this.m_expiresAt = user.expiresAt;
          this.m_loggedIn = true;
        },

        logout() {
          this.m_loggedIn = false;
          this.m_username = "";
          this.m_userId = "";
          this.m_role = "";
          this.m_expiresAt = 0;
        }
      }
    });
  }

  init(vue) {
    console.log("init", vue);
    this.m_vue = vue;
  }

  // sets current user
  async setUser(user) {

    // if there's already user set and it's admin then stop adminhub connection
    if (roles.isAdmin(this.data.role))
      await AdminHub.stop();

    // set store data
    this.data.setUser(user);

    // set api token
    api.setToken(user.token, user.expiresAt);

    // start adminhub connection if user is admin
    if (roles.isAdmin(user.role))
      await AdminHub.start(user.token);
  }

  // saves user to local storage
  saveUser(user) {
    localStorage.current_user = json5.stringify(user);
  }

  // loads and sets user from local storage
  async loadUser() {

    if (!localStorage.current_user)
      return;

    let user = json5.parse(localStorage.current_user);

    // check if one of the fields not set
    if (!user.token || !user.username || !user.userId || !user.role || !user.expiresAt) {
      localStorage.removeItem("current_user");
      return;
    }

    // check if user token expired
    let currentTime = Math.round(Date.now() / 1000);
    if (currentTime >= user.expiresAt) {
      localStorage.removeItem("current_user");
      return;
    }

    // load user
    await this.setUser(user);
  }

  // logins using code, saves and sets user
  async login(code) {
    let timeBefore = Math.round(Date.now() / 1000);
    let result = await api.authenticate(code, this.getCallbackUri());

    if (!result.isSuccess)
      return result;

    let user = userFromPayload(result.payload, timeBefore);

    this.saveUser(user);
    this.setUser(user);

    return result;
  }

  // logout user
  async logout() {
    localStorage.removeItem("current_user");

    if (roles.isAdmin(this.data.role))
      await AdminHub.stop();

    this.data.logout();
    api.setToken(null);

    router.push({name: "Home"});
  }

  // refreshes token
  async refresh() {
    let timeBefore = Math.round(Date.now() / 1000);
    let result = await api.refreshToken(this.getCallbackUri());

    if (!result.isSuccess)
      return result;

    let user = userFromPayload(result.payload, timeBefore);

    this.saveUser(user);
    this.setUser(user);

    return result;
  }


  getCallbackUri() {
    // get login callback url
    let route = router.resolve({name: "Login callback"});
    let origin = window.location.origin;
    return origin + route.href;
  }
}

/*



 */
