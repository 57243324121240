import axios from "axios";
import json5 from "json5";

let _api = () => {
    let api = {
        url: null,
        dungeon: {},
        guild: {},
        admin: {
            url: null,
            commands: {}
        },
        browse: {},
    };

    let token = null;
    let tokenExpiresAt = 0;
    let tokenHeader = null;
    let configWithToken = null;

    //#region Helpers
    api.setToken = (newToken, expiresAt) => {

        if (!newToken) {
            token = null;
            tokenExpiresAt = 0;
            tokenHeader = null;
            configWithToken = null;
            return;
        }

        assert.isNumber(expiresAt, nameof({expiresAt}));

        if (expiresAt <= Math.round(Date.now() / 1000))
            throw "Expires at is lower than current time";

        token = newToken;
        tokenExpiresAt = expiresAt;
        tokenHeader = "Bearer " + newToken;
        configWithToken = {
            headers: {
                Authorization: tokenHeader
            }
        };
    };

    let assert = {};
    assert.authenticated = () => {
        if (token === null) throw "User not authenticated";
        if (Math.round(Date.now() / 1000) >= tokenExpiresAt) throw "User token is expired";
        return assert;
    };

    assert.initialized = () => {
        if (api.url === null || api.admin.url === null)
            throw "API url not initialized";
        return assert;
    };

    assert.isNumber = (s, argumentName) => {
        if (typeof s === "number") return assert;

        if (typeof s !== "string") throw `${argumentName} is not a number`;

        for (let i = s.length - 1; i >= 0; i--) {
            const d = s.charCodeAt(i);
            if (d < 48 || d > 57) throw `${argumentName} is not a number`;
        }
        return assert;
    };

    function nameof(_) {
        return Object.keys(_)[0];
    }

    //#endregion

    //#region Guild
    {
        api.guild.list = async () => {
            assert.initialized();

            return (await axios.get(`${api.url}/guild/list`, configWithToken)).data;
        };

        api.guild.view = async (guildId) => {
            assert.initialized()
                .isNumber(guildId, nameof({guildId}));

            return (await axios.get(`${api.url}/guild/${guildId}`, configWithToken)).data;
        }
    }
    //#endregion

    //#region Dungeon
    {
        api.dungeon.view = async (guildId, dungeonId) => {
            assert
                .initialized()
                .isNumber(guildId, nameof({guildId}))
                .isNumber(dungeonId, nameof({dungeonId}));

            return axios.get(
                `${api.url}/dungeon/${guildId}/${dungeonId}`,
                configWithToken
            );
        };

        api.dungeon.list = async () => {
            assert.initialized();

            return (await axios.get(`${api.url}/dungeon/list`, configWithToken)).data;
        };

        api.dungeon.deleteEntry = async (guildId, dungeonId, entryId) => {
            assert
                .initialized()
                .isNumber(guildId, nameof({guildId}))
                .isNumber(dungeonId, nameof({dungeonId}))
                .isNumber(entryId, nameof({entryId}))
                .authenticated();

            return await axios.delete(
                `${api.url}/dungeon/${guildId}/${dungeonId}/${entryId}`,
                {
                    validateStatus: () => true,
                    headers: {Authorization: tokenHeader}
                }
            );
        };

        api.dungeon.update = async (dungeon, guildId, dungeonId) => {
            assert
                .initialized()
                .isNumber(guildId, nameof({guildId}))
                .isNumber(dungeonId, nameof({dungeonId}))
                .authenticated();

            return await axios.post(
                `${api.url}/dungeon/${guildId}/${dungeonId}`,
                {
                    dungeon: {
                        name: dungeon.name,
                        metadata: dungeon.metadata
                    }
                },
                {
                    validateStatus: () => true,
                    headers: {Authorization: tokenHeader}
                }
            );
        };
    }
    //#endregion

    //#region Authenticate
    {
        api.authenticate = async (code, redirect_uri) => {
            assert.initialized();

            let response;

            try {
                response = await axios.post(
                    `${api.url}/auth`,
                    {
                        code: code,
                        redirect_uri: redirect_uri
                    },
                    {validateStatus: () => true}
                );

                if (response.status !== 200) {
                    if (typeof response === "object" && typeof response.data === "object")
                        return response.data;

                    return {
                        isSuccess: false,
                        failureReason: "Bad status code: " + response.status
                    };
                }
            } catch (e) {
                return {
                    isSuccess: false,
                    failureReason: e
                };
            }

            return response.data;
        };

        api.refreshToken = async (redirect_uri) => {
            assert
                .initialized()
                .authenticated();

            let response;

            try {
                response = await axios.post(
                    `${api.url}/auth/refresh`,
                    {redirect_uri: redirect_uri},
                    configWithToken);

                if (response.status !== 200) {
                    if (typeof response === "object" && typeof response.data === "object")
                        return response.data;

                    return {
                        isSuccess: false,
                        failureReason: "Bad status code: " + response.status
                    };
                }
            } catch (e) {
                return {
                    isSuccess: false,
                    failureReason: e
                };
            }

            return response.data;
        };
    }
    //#endregion

    //#region Admin command editor
    {
        // INDEX
        api.admin.commands.index = async () => {
            assert.initialized().authenticated();

            return await axios.get(`${api.admin.url}/commands`, configWithToken);
        };

        // GET COMMAND
        api.admin.commands.get = async commandId => {
            assert
                .initialized()
                .authenticated()
                .isNumber(commandId, nameof({commandId}));

            commandId = parseInt(commandId);

            return await axios.get(
                `${api.admin.url}/commands/${commandId}`,
                configWithToken
            );
        };

        // UPDATE COMMAND
        api.admin.commands.update = async (command, updateGuid) => {
            assert.initialized().authenticated();

            if (command === undefined || command.commandId === undefined)
                throw "command or command id is undefined";

            command = Object.assign({}, command);
            command.metadata = json5.stringify(command.metadata);

            return await axios.post(
                `${api.admin.url}/commands/${command.commandId}`,
                {
                    command: command,
                    updateGuid: updateGuid
                },
                configWithToken
            );
        };

        // DELETE COMMAND
        api.admin.commands.delete = async commandId => {
            assert
                .initialized()
                .isNumber(commandId, nameof({commandId}))
                .authenticated();

            return await axios.delete(
                `${api.admin.url}/commands/${commandId}`,
                configWithToken
            );
        };

        // CREATE COMMAND
        api.admin.commands.create = async command => {
            assert.initialized().authenticated();

            if (command === undefined) throw "command is undefined";

            command = Object.assign({}, command);
            command.metadata = json5.stringify(command.metadata);

            return await axios.post(
                `${api.admin.url}/commands/create`,
                {
                    command: command
                },
                {
                    validateStatus: () => true,
                    headers: {Authorization: tokenHeader}
                }
            );
        };

        // PREVIEW COMMAND
        api.admin.commands.preview = async (
            query,
            source,
            rating = 0,
            lastId = 0,
            count = 20
        ) => {
            assert.initialized().authenticated();

            return await axios.get(`${api.admin.url}/commands/preview`, {
                params: {
                    // query needs to be generated from properties in
                    // this order source.query ?? meta.query ?? command.name
                    query: query,
                    source: source,
                    rating: rating,
                    count: count,
                    lastId: lastId,
                    useBanList: true
                },
                validateStatus: () => true,
                headers: {Authorization: tokenHeader}
            });
        };

        // INDEX COMMAND
        api.admin.commands.indexQuery = async commandId => {
            assert
                .initialized()
                .authenticated()
                .isNumber(commandId, nameof({commandId}));

            return await axios.post(
                `${api.admin.url}/commands/${commandId}/indexquery`,
                {},
                {
                    validateStatus: () => true,
                    headers: {Authorization: tokenHeader}
                }
            );
        };
    }
    //#endregion

    //#region Browse
    {
        api.browse.random = async (category, count) => {
            assert
                .initialized()
                .isNumber(count, nameof({count}));

            return axios.get(
                `${api.url}/browse/${category}`,
                {
                    headers: {Authorization: tokenHeader},
                    params: {
                        count
                    }
                }
            );
        };

        api.browse.categories = async () => {
            assert
                .initialized();

            return axios.get(
                `${api.url}/browse`,
                configWithToken
            );
        };

        api.browse.like = async (source, postId) => {
            assert
                .initialized();

            return axios.post(
                `${api.url}/browse/like`,
                {
                    source: source,
                    postId: postId
                },
                {
                    headers: {Authorization: tokenHeader},
                }
            );
        }
    }
    //#endregion

    return api;
};

export default _api();
