<template>
    <Header class="mb-4"></Header>
    <slot/>
    <Footer></Footer>
</template>

<script>
    import emitter from 'tiny-emitter/instance';
    import Footer from "./Footer.vue";
    import Header from "./Header.vue";

    export default {
        name: "AdminLayout",
        components: {
            Footer,
            Header
        },
        methods: {
            onCommandDeleted(commandName) {
                this.$toasted.show(`Command '${commandName}' deleted`, {
                    theme: "toasted-primary",
                    position: "bottom-right",
                    duration: 3000
                });
            }
        },
        created() {
            emitter.on("CommandDeleted", this.onCommandDeleted);
        },
        beforeDestroy() {
            emitter.off("CommandDeleted", this.onCommandDeleted);
        }
    };
</script>

<style>
    body {
        background-color: #fff;
    }
</style>
