<template>
    <footer class="footer container mt-4">
        <p>
            Copyright &copy; 2019-2023 - <a href="https://scuff.pl/">scuff.pl</a>
        </p>
    </footer>
</template>

<script>
    export default {
        name: "Footer",
    };
</script>
