<template>
    <backtotop></backtotop>
    <component :is="layout">
        <router-view></router-view>
    </component>
</template>

<script>
    const default_layout = "default";

    export default {
        name: "app",
        computed: {
            layout() {
                return (this.$route.meta.layout || default_layout) + "-layout";
            }
        }
    };
</script>
