export default {
    _modules: [
        "nsfw",
        "meme"
    ],

    _nsfwDefaultSourcesFactory() {
        return {}
    },

    /* nsfw sources */
    setDefaultNsfwSourcesFactory(sourcesFactory) {
        this._nsfwDefaultSourcesFactory = sourcesFactory;
    },

    getNsfwSources() {
        return this._nsfwDefaultSourcesFactory();
    },

    /* modules */
    setModules(modules) {
        this._modules = modules
    },

    getModules() {
        return this._modules;
    }
}