<template>
    <Header></Header>
    <slot/>
    <Footer></Footer>
</template>

<script>
    import Footer from "./Footer.vue";
    import Header from "./Header.vue";

    export default {
        name: "DefaultNoMarginLayout",
        components: {
            Footer,
            Header
        }
    };
</script>

<style>
    body {
        background-color: #fff;
    }
</style>
