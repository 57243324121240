<template>
    <b-navbar id="navbar" toggleable="lg" type="dark" variant="dark">
        <div class="container">
            <b-navbar-brand href="#">NBot</b-navbar-brand>
            <b-navbar-toggle target="nav_collapse"/>
            <b-collapse id="nav_collapse" is-nav>
                <b-navbar-nav>
                    <b-nav-item to="/">Home</b-nav-item>

                    <!-- admin items -->
                    <b-nav-item-dropdown right text="Admin" v-if="this.$userManager.data.isAdmin">
                        <b-dropdown-item :to="{ name: 'Admin Home' }">Home</b-dropdown-item>
                        <b-dropdown-item :to="{ name: 'Commands List' }"
                        >Commands
                        </b-dropdown-item
                        >
                    </b-nav-item-dropdown>

                    <!-- logged in items -->
                    <template v-if="this.$userManager.data.isLoggedIn">
                        <b-nav-item :to="{ name: 'Dungeon List' }">Dungeons</b-nav-item>
                        <b-nav-item :to="{ name: 'Guild List' }">Guilds</b-nav-item>
                        <b-nav-item :to="{ name: 'Browse Picture Categories' }">Browse</b-nav-item>
                    </template>

                    <!-- not logged in -->
                    <b-nav-item :to="{ name: 'Login' }" v-else>Login</b-nav-item>
                </b-navbar-nav>

                <!-- right side -->
                <b-navbar-nav class="ml-auto text-white-50" v-if="this.$userManager.data.isLoggedIn">
                    <b-nav-text>
                        Logged in as: {{ this.$userManager.data.username }}
                        <em class="d-none d-lg-inline"> |</em>
                    </b-nav-text>
                    <b-nav-item @click="logout">Log out</b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </div>
    </b-navbar>
</template>

<script>
    export default {
        name: "Header",
        methods: {
            async logout() {
                await this.$userManager.logout();
            }
        }
    };
</script>
