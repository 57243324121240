import api from "@/js/api";

export default class Cache {
    constructor() {
        this.m_categories = null;
    }

    async getCategories() {
        if (this.m_categories)
            return this.m_categories;

        let response = await api.browse.categories();
        this.m_categories = response.data;

        return this.m_categories;
    }
}
